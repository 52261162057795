<template>
  <div class="CaseMainFinance">
    <div class="financePart">
      <div class="financItem">
        <div class="title">
          <div class="iconfont iconqian"></div>
          <div class="titleText">合同预估</div>
        </div>
        <div class="count">
          <div class="iconfont iconqian1"></div>
          <div class="countNumber">12000.00</div>
        </div>
      </div>
      <div class="financItem">
        <div class="title">
          <div class="iconfont iconqian"></div>
          <div class="titleText">已收金额</div>
        </div>
        <div class="count">
          <div class="iconfont iconqian1"></div>
          <div class="countNumber">2321.34</div>
        </div>
      </div>
      <div class="financItem">
        <div class="title">
          <div class="iconfont iconqian"></div>
          <div class="titleText">已开票金额</div>
        </div>
        <div class="count">
          <div class="iconfont iconqian1"></div>
          <div class="countNumber">223.30</div>
        </div>
      </div>
      <div class="financItem">
        <div class="title">
          <div class="iconfont iconqian"></div>
          <div class="titleText">支出金额</div>
        </div>
        <div class="count">
          <div class="iconfont iconqian1"></div>
          <div class="countNumber">23424.00</div>
        </div>
      </div>
    </div>
    <div class="financeList">
      <div class="leftMenu">
        <div class="menuIcon menuOn">
          <div class="iconfont iconshouru"></div>
          <div class="iconText">收入</div>
          <div class="barIcon"></div>
        </div>
        <div class="menuIcon">
          <div class="iconfont iconzhichu"></div>
          <div class="iconText">支出</div>
          <div class="barIcon"></div>
        </div>
      </div>
      <div class="rightContent">
        <div class="topBtn">
          <el-button type='primary' icon='el-icon-plus' size="small" round>添加</el-button>
        </div>
        <div class="rightTable">
          <el-table
            :data="tableData"
            style="width: 100%">
            <el-table-column
              prop="name"
              label="名称"
              width="180">
            </el-table-column>
            <el-table-column
              prop="acount"
              label="金额（元）"
              width="180">
            </el-table-column>
            <el-table-column
              prop="payType"
              label="付款方式">
            </el-table-column>
            <el-table-column
              prop="date"
              label="支付时间">
            </el-table-column>
            <el-table-column
              label="操作"
              fixed="right"
              width="100"
              align='center'>
              <template #default="scope">
                <el-dropdown trigger="click" placement="bottom">
                <div class="iconfont icongengduo" @click='more(scope)'></div>
                <template #dropdown>
                  <el-dropdown-menu>
                      <el-dropdown-item>编辑</el-dropdown-item>
                      <el-dropdown-item>删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paginationBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
export default defineComponent({
  name:"ProjectMainFinance",
  data(){
    return {
      tableData: [{
          date: '2016-05-02 12:20',
          name: '招待费',
          acount: '1500.00',
          payType:'现金'
        }, {
          date: '2016-05-02 12:20',
          name: '招待费',
          acount: '950.00',
          payType:'支付宝'
        }, {
          date: '2016-05-02 12:20',
          name: '检查费',
          acount: '100.00',
          payType:'现金'
        }, {
          date: '2016-05-02 12:20',
          name: '住宿费',
          acount: '500.00',
          payType:'微信'
      }]
    }
  },
  methods:{
    more(scope){
      console.log(scope)
    },
    handleSizeChange(){},
    handleCurrentChange(){},

  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.CaseMainFinance{
  width:100%;
  padding-bottom:30px;
  .financePart{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding:30px 0;
    box-sizing: border-box;
    .financItem{
      width:25%;
      height:120px;
      box-sizing: border-box;
      border-right: 1px solid #eee;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:last-child{
        border:none
      }
      .title{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom:20px;
        .iconqian{
          font-size: 26px;
          color:$main-color;
          margin-right: 10px;
        }
        .titleText{
          font-size: 16px;
          color:#666
        }
      }
      .count{
        display: flex;
        align-items: center;
        justify-content: center;
        .iconqian1{
          font-size: 20px;
          color:#66C5FF;
          margin-right: 6px;
          font-weight: 800;
        }
        .countNumber{
          font-size: 20px;
          color:#66C5FF
        }
      }
    }
  }
  .financeList{
    border-top:1px solid #eee;
    width:1140px;
    box-sizing: border-box;
    margin:0 auto;
    display: flex;
    .leftMenu{
      width:120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .menuIcon{
        display: flex;
        align-items: center;
        width:100%;
        padding:20px;
        box-sizing: border-box;
        position: relative;
        .barIcon{
          display: none;
          position: absolute;
          right:14px;
          top:13px;
          width:1px;
          height:40px;
          border-right: 3px solid $main-color;
        }
        &:hover{
          cursor: pointer;
          color:$main-color;
          .iconText,.iconfont{
            cursor: pointer;
            color:$main-color;
          }
          .barIcon{
            display: block;
          }
        }
        .iconfont{
          font-size: 26px;
          color:#777;
        }
        .iconText{
          font-size: 14px;
          color:#666666;
          margin-left:10px;
        }
      }
      .menuOn{
        .barIcon{
          display: block;
        }
        .iconText,.iconfont{
          color:$main-color;
        }
      }
    }
    .rightContent{
      width:1020px;
      border-left:1px solid #eee;
      .topBtn{
        display: flex;
        justify-content: flex-end;
        padding:20px 0;
        border-bottom:1px solid #eee;
      }
      .icongengduo{
        &:hover{
          cursor: pointer;
          color:$main-color;
        }
      }
      .paginationBox{
        width:100%;
        display: flex;
        justify-content: flex-end;
        margin-top:50px;
      }
    }
  }
}
</style>